<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
                label="Név"
            >
              <validation-provider
                  #default="{ errors }"
                  name="név"
                  rules="required"
                  vid="name"
              >
                <b-form-input
                    v-model="formData.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="név"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Felhasználónév"
            >
              <validation-provider
                  #default="{ errors }"
                  name="felhasználónév"
                  rules="required"
                  vid="username"
              >
                <b-form-input
                    v-model="formData.username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="felhasználónév"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Email cím"
            >
              <validation-provider
                  #default="{ errors }"
                  name="email cím"
                  rules="required|email"
                  vid="email"
              >
                <b-form-input
                    v-model="formData.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="teszt@maconka.hu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Jelszó"
            >
              <validation-provider
                  #default="{ errors }"
                  name="jelszó"
                  rules="required"
                  vid="password"
              >
                <b-form-input
                    v-model="formData.password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="***********"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Jelszó ismét"
            >
              <validation-provider
                  #default="{ errors }"
                  name="jelszó ismét"
                  rules="required"
                  vid="confirm_password"
              >
                <b-form-input
                    v-model="formData.confirm_password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="***********"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
                label="Szerepkör"
            >
              <validation-provider
                  #default="{ errors }"
                  name="szerepkör"
                  rules=""
                  vid="role"
              >
                <v-select
                    v-model="formData.role"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="roleOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Telefonszám"
            >
              <validation-provider
                  ref="phoneValidator"
                  #default="{ errors }"
                  name="telefonszám"
                  rules=""
                  vid="phone"
              >
                <VuePhoneNumberInput
                    v-model="formData.phone"
                    :error="errors.length > 0"
                    placeholder="telefonszám"
                    default-country-code="HU"
                    color="#42968E"
                    valid-color="#404656"
                    error-color="#EA5455"
                    dark-color="#283046"
                    :no-example="true"
                    :dark="$store.state.appConfig.layout.skin === 'dark'"
                    :translations="{
                      countrySelectorLabel: 'Ország',
                      countrySelectorError: 'Válassz egy országot',
                      phoneNumberLabel: 'Telefonszám'
                    }"
                    @update="phoneChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Ország"
            >
              <validation-provider
                  #default="{ errors }"
                  name="ország"
                  rules=""
                  vid="country"
              >
                <v-select
                    v-model="formData.country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="countryOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Irányítószám"
            >
              <validation-provider
                  #default="{ errors }"
                  name="irányítószám"
                  rules=""
                  vid="zip"
              >
                <b-form-input
                    v-model="formData.zip"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Település"
            >
              <validation-provider
                  #default="{ errors }"
                  name="település"
                  rules=""
                  vid="city"
              >
                <b-form-input
                    v-model="formData.city"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Cím"
            >
              <validation-provider
                  #default="{ errors }"
                  name="cím"
                  rules=""
                  vid="address1"
              >
                <b-form-input
                    v-model="formData.address1"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
                label="Cím kiegészítés"
            >
              <validation-provider
                  #default="{ errors }"
                  name="cím kiegészítés"
                  vid=""
              >
                <b-form-input
                    v-model="formData.address2"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Jogcím"
            >
              <validation-provider
                  #default="{ errors }"
                  name="jogcím"
                  rules=""
                  vid="title"
              >
                <v-select
                    v-model="formData.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :options="titleOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Megjegyzés"
            >
              <validation-provider
                  #default="{ errors }"
                  name="megjegyzés"
                  vid="comment"
              >
                <editor
                    v-model="formData.comment"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <validation-provider
                #default="{ errors }"
                name="mező"
                vid="email_is_public"
            >
              <b-form-checkbox
                  v-model="formData.email_is_public"
                  :state="errors.length > 0 ? false:null"
              >
                Publikus az e-mail cím?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
                #default="{ errors }"
                name="mező"
                vid="accept_newsletters"
            >
              <b-form-checkbox
                  v-model="formData.accept_newsletters"
                  :state="errors.length > 0 ? false:null"
              >
                Hírlevél feliratkozás?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
                #default="{ errors }"
                name="mező"
                vid="can_use_reservation"
            >
              <b-form-checkbox
                  v-model="formData.can_use_reservation"
                  :state="errors.length > 0 ? false:null"
              >
                Használhatja a helyfoglalást?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import editor from "@/views/pages/_components/editor.vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    cancelAndGOTOPreviousPage,
    editor,
    VuePhoneNumberInput
  },
  data() {
    return {
      role: 'user',
      formData: {
        name: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        role: '',
        comment: '',
        email_is_public: false,
        accept_newsletters:false,
        can_use_reservation: true,
        title: '',
        phone: '',
        phoneInternational: '',
        country: '',
        zip: '',
        city: '',
        address1: '',
        address2: '',
      },
      roleOptions: [],
      titleOptions: [],
      countryOptions: [],
      required,
      email,
    }
  },
  created() {
    this.$store.dispatch('fetchRolesForSelect').then(response => {
      this.roleOptions = response

      if (this.roleOptions.length) {
        // nem kötelező, ezáltal nem kell default érték
        // this.formData.role = this.roleOptions[0]
      }
    })

    this.$store.dispatch('fetchUserTitlesForSelect').then(response => {
      this.titleOptions = response

      if (this.titleOptions.length) {
        this.formData.title = this.titleOptions[0]
      }
    })

    this.$store.dispatch('fetchNationalitiesForSelect').then(response => {
      this.countryOptions = response
    })
  },
  methods: {
    phoneChanged(e) {
      this.$refs.phoneValidator.reset()
      if (this.formData.phone.length && !e.isValid) {
        this.$refs.phoneValidator.validate().then(() => {
          const err = {
            phone: ['A telefonszám formátuma érvénytelen.']
          }
          this.$refs.simpleRules.setErrors(err)
        })
      }
      this.formData.phoneInternational = e.formatInternational
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          if (this.formData.role) {
            fd.role = this.formData.role.value
          }
          if (this.formData.country) {
            fd.country = this.formData.country.value
          }
          fd.title = this.formData.title.value

          this.$store.dispatch('storeUser', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({ name: 'users' })
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
